import React, { useEffect, useRef } from "react";
import useMutationObserver from "@rooks/use-mutation-observer";
import { useAnalytics } from "@components/analytics";

declare global {
  interface Window {
    MailerLiteObject?: string;
    ml: any;
  }
}

type HiddenField = {
  name: string;
  value: string;
};

export enum Appearance {
  dark = "dark",
  light = "light",
}

type SubmitListener = (event: SubmitEvent) => void;

type NewsletterFormProps = {
  formId: string;
  formName: string;
  additionalInfo?: string;
  hiddenFields?: Array<HiddenField>;
  onSubmit?: () => void;
  appearance?: Appearance;
};

const updateHiddenFields = (hiddenFields: Array<HiddenField> = []): void => {
  (hiddenFields || []).forEach(({ name, value }) => {
    const fields = document.getElementsByName(
      `fields[${name}]`
    ) as NodeListOf<HTMLInputElement>;
    fields.forEach((field) => {
      field.value = value || "";
      field.style.display = "none";
    });
  });
};

function getTextColor(appearance: Appearance): string {
  switch (appearance) {
    case Appearance.dark:
      return "white";
    default:
      return "rgb(55, 65, 81)";
  }
}

function replaceMarkdown(text: string) {
  return text.replace(/~~([^~]*)~~/g, "<s>$1</s>");
}

const mailerliteClassicToNewFormId = {
  "5807132:r2q9a9": "uRlhGF", // Profy - Email Course (React On The Job)
  "5803065:f7p4w5": "NTeJR9", // Profy - React End-To-End Types
  "5797560:y3l2t9": "kSK7H7", // Profy - React API Layer
  "5792675:x8n7r6": "9JJ4VR", //  Profy - React & REST APIs
  "5781447:w1x3x7": "cnSyOA", // RJS Coding Challenges (Pair Programming Interview)
  "5776319:l5j5l8": "XwlcMF", // Profy - Testing With Cypress
  "5771155:o6i9g5": "9Z5BoG", // Profy - Debug React With VS Code
  "5764565:w9r9r0": "x21Wpk", //  Profy - RJS Coding Challenges (React Projects Article)
  "5760447:o6z3a1": "MhpJ7q", // Profy - RJS Coding Challenges
  "5754818:q7x3g6": "oVAbLZ", // Profy - Email Course (Around React)
  "5360750:w7x8x5": "bilP0N", // Profy - Waitlist
  "4788278:n8l3t3": "jVJQ91", // Profy - Waitlist Onboarding Courses
  "4777949:s8l3c5": "GXnZwV", // Profy - Apply GitHub Flow in Own Projects
  "4738961:b2m0m1": "AbNrj4", // Profy - Advanced GitHub Flow Waitlist
  "4469434:m8r8a8": "csH2W9", // Profy - First Dev Job Ebook
  "4385926:o3k2j5": "QkBaxK", // Profy - Portfolio Project Readme Template
  "4385791:l5b9g3": "8brjXG", // Profy - Portfolio Project Checklist
  "3803068:g9x9x1": "v4XeLv", // Profy - Weekend Club
  "3614794:p8h7s8": "0QV6vk", // Profy - Project Waitlist
} as Record<string, string>;

const NewsletterForm: React.FC<NewsletterFormProps> = ({
  formId,
  formName,
  additionalInfo,
  hiddenFields,
  onSubmit,
  appearance = Appearance.light,
}) => {
  const { trackPlausibleEvent, trackPiwikEvent } = useAnalytics();
  const submitListener = useRef<SubmitListener | null>(null);

  useEffect(() => {
    if (window.ml) {
      return;
    }

    window.ml = function () {
      if (!window.ml.q) {
        window.ml.q = [];
      }
      window.ml.q.push(arguments);
    };

    const mlScriptUrl = "https://assets.mailerlite.com/js/universal.js";
    const script = document.createElement("script");
    script.async = true;
    script.src = mlScriptUrl;
    document.head.appendChild(script);

    window.ml("account", "127980");

    return () => {
      if (!window.ml) {
        return;
      }
      const scripts = document.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mlScriptUrl)) {
          try {
            document.head.removeChild(scripts[i]);
            window.ml = undefined;
          } catch (e) {
            console.error(e);
          }
        }
      }
    };
  }, []);

  const formRef = useRef<HTMLDivElement>(null);
  const callback = (mutationList: any, observer: MutationObserver) => {
    if (!formRef.current) {
      return;
    }

    const inputs = formRef.current.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i += 1) {
      const input = inputs[i];
      const label = input.placeholder;
      if (label) {
        input.setAttribute("aria-label", label);
      }
    }

    const form = formRef.current.getElementsByTagName("form")[0];
    submitListener.current = (event: SubmitEvent) => {
      trackPlausibleEvent("Subscribe To Newsletter", {
        formId,
        formName,
        additionalInfo,
      });
      trackPiwikEvent({
        category: "Newsletter",
        action: "Subscribe",
        name: formName,
        dimensions: {
          formId,
          additionalInfo,
        },
      });
      if (onSubmit) {
        onSubmit();
      }
    };
    form.addEventListener("submit", submitListener.current);

    const buttons = formRef.current.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i += 1) {
      buttons[i].innerHTML = replaceMarkdown(buttons[i].innerHTML);
    }

    updateHiddenFields(hiddenFields);
    observer.disconnect();
  };
  useMutationObserver(formRef, callback);

  useEffect(() => {
    updateHiddenFields(hiddenFields);
  }, [hiddenFields]);

  useEffect(
    () => () => {
      if (submitListener.current && formRef.current) {
        const form = formRef.current.getElementsByTagName("form")[0];
        form.removeEventListener("submit", submitListener.current);
        submitListener.current = null;
      }
    },
    []
  );

  const wrapperId = `nl-${formId.replace(":", "")}`;
  const newMailerliteFormId = mailerliteClassicToNewFormId[formId] || formId;

  return (
    <div
      ref={formRef}
      id={wrapperId}
      className="ml-embedded"
      data-form={newMailerliteFormId}
    >
      <style jsx>{`
        &,
        & :global(h4),
        & :global(p),
        & :global(ul),
        & :global(strong) {
          font-size: 18px !important;
          color: ${getTextColor(appearance)} !important;
          line-height: 1.7 !important;
          font-family: Lato, sans-serif !important;
        }

        & :global(h4) {
          text-align: center !important;
          font-size: 2em !important;
          margin-bottom: 0.5em !important;
          line-height: 1.2 !important;
          font-family: Raleway, sans-serif !important;
        }

        & :global(ul) {
          list-style: disc;
          padding-left: 20px;
          margin-top: 10px;
        }

        & :global(li) {
          padding-left: 0 !important;
        }

        & :global(li)::before {
          display: none;
        }

        & :global(form) {
          margin-top: 2em !important;
        }

        & :global(.embedForm) {
          background: none !important;
        }

        & :global(.ml-form-embedContent) {
          margin: 0 !important;
        }

        & :global(.ml-form-embedSubmitLoad),
        & :global(.ml-form-embedSubmitLoad):after {
          width: 20px;
          height: 20px;
        }

        & :global(button),
        & :global(input) {
          height: 48px !important;
        }

        #${wrapperId}
          :global(.ml-form-embedContainer)
          :global(.ml-form-embedWrapper)
          :global(.ml-form-embedBody)
          :global(.ml-form-fieldRow)
          :global(input) {
          font-size: 16px !important;
        }

        & :global(s) {
          text-decoration: none;
          position: relative;
          margin: 0 4px;
        }

        & :global(s)::after {
          content: " ";
          position: absolute;
          top: 50%;
          height: 2px;
          left: -5px;
          right: -5px;
          transform: rotate(-15deg);
          background: red;
        }
      `}</style>
    </div>
  );
};

export default NewsletterForm;
