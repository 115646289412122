import { isEqual } from "lodash";
import dynamic from "next/dynamic";
import React, { useContext, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

const NewsletterModal = dynamic(() => import("@components/newsletter-modal"), {
  ssr: false,
});

type HiddenFields = Array<{ name: string; value: string }>;

export enum Appearance {
  light = "light",
  pop = "pop",
}

export type FormData = {
  formId: string;
  formName: string;
  hiddenFields?: HiddenFields;
  appearance?: Appearance;
};

type NewsletterModalContextType = Partial<FormData> & {
  isOpen: boolean;
  init: (formData: FormData) => void;
  open: (formId: string) => void;
  close: () => void;
};

type NewsletterModalProviderProps = {
  children: React.ReactNode;
};

export const NewsletterModalContext =
  React.createContext<NewsletterModalContextType>({
    formId: undefined,
    formName: undefined,
    hiddenFields: undefined,
    isOpen: false,
    init: () => null,
    open: () => null,
    close: () => null,
  });

export const NewsletterModalProvider = ({
  children,
}: NewsletterModalProviderProps) => {
  const [formData, setFormData] = useState<FormData>();
  const [openFormId, setOpenFormId] = useState<string>();

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
  }, []);

  const init = (newFormData: FormData) => {
    if (mounted.current) {
      console.log("Only init form during initial mount");
    }

    if (!isEqual(formData, newFormData)) {
      setFormData(newFormData);
    }
  };

  return (
    <NewsletterModalContext.Provider
      value={{
        ...formData,
        isOpen: !!formData?.formId && formData?.formId === openFormId,
        init,
        open: (formId) => setOpenFormId(formId),
        close: () => setOpenFormId(undefined),
      }}
    >
      {children}

      <NewsletterModal />
    </NewsletterModalContext.Provider>
  );
};

export function useNewsletterModal(formData: FormData) {
  const { init, open, ...other } = useContext(NewsletterModalContext);

  useEffect(() => {
    init(formData);
  }, []);

  return {
    ...other,
    open: () => open(formData.formId),
  };
}
