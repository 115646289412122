import React, { useContext } from "react";
import ReactDOM from "react-dom";
import {
  Appearance as ModalAppearance,
  NewsletterModalContext,
} from "./modal-context";
import NewsletterForm, {
  Appearance as NewsletterAppearance,
} from "@components/newsletter-form";

declare global {
  interface Window {
    MailerLiteObject?: string;
    ml: any;
  }
}

function useClickOutside<T extends HTMLElement>(callback: () => void) {
  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    const handleClick: EventListener = (event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return ref;
}

const NewsletterSignupModal = () => {
  const {
    formId,
    formName,
    hiddenFields,
    appearance = ModalAppearance.light,
    isOpen,
    close,
  } = useContext(NewsletterModalContext);

  const ref = useClickOutside<HTMLDivElement>(close);

  if (!formId || !formName) {
    console.error("formId and formName have to be provided");
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50 ${
        isOpen ? "block" : "hidden"
      }`}
      style={{ backdropFilter: "blur(3px)" }}
    >
      <div
        ref={ref}
        className="max-w-3xl bg-white shadow-md rounded-md px-8 pt-12 pb-6 relative"
        style={
          appearance === ModalAppearance.pop
            ? {
                background: "#E63946",
                border: "5px dashed #FFFFFF",
                color: "#FFFFFF",
              }
            : undefined
        }
      >
        {formId && formName && (
          <NewsletterForm
            formId={formId}
            formName={formName}
            hiddenFields={hiddenFields}
            appearance={
              appearance === ModalAppearance.pop
                ? NewsletterAppearance.dark
                : NewsletterAppearance.light
            }
          />
        )}
        <button
          type="button"
          className={`w-10 h-10 absolute right-2 top-2 ${
            appearance === ModalAppearance.pop ? "text-white" : "text-gray-500"
          }`}
          onClick={() => close()}
        >
          {/* hero icon x */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("modal")!
  );
};

export default NewsletterSignupModal;
